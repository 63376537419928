<template>
	<div class="patents--page">
		<section class="patents--list">
			<div class="title">
				<h2>
					<span v-for="(title, index) in $t('brevetti.titolo').split('&')" :key="index">
						{{title}}
						<AndSVG v-if="index == 0" />
					</span>
				</h2>
			</div>
			<div class="list--container" >
				<div class="list--wrapper">
					<div class="patent" v-for="(patent, index) in patents" :key="index">
						<router-link :to="$i18nRoute({ name: 'Patent', params: {slug: patent.slug} })" :title="patent.title.rendered" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')">
							<div class="image">
								<ImageById :media_id="patent['toolset-meta']['brevetti']['logo-brevetto']['attachment_id']" />
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import axios from 'axios'
import ImageById from '@/components/ImageById';
import AndSVG from '@/components/elements/AndSVG';
export default {
	data() {
		return {
			carousel: '',
			container: '',
			patents: {},
		}
	},
	components: {
		AndSVG,
		ImageById
	},
	metaInfo() {
		return{
			title: this.$t('head.title.applications'),
			titleTemplate: '%s | Wearlight',
		}
  },
	methods: {
		
	},
	beforeMount() {
		axios
			.get(process.env.VUE_APP_API_URL + '/wp/v2/brevetto/')
			.then(response => {
				this.patents = response.data
			});
	}
}
</script>

<style lang="scss">
.patents--page {
	position: relative;
	width: 100%;
	padding-top: 80px;
	height: 100vh;
	box-sizing: border-box;
	@include mobile {
		padding-top: 60px;
		height: auto;
		padding-bottom: 10vh;
	}
	.patents--list {
		position: relative;
		width: 100%;
		height: calc(100% - 80px);
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		padding-right: 1vw;
		box-sizing: border-box;
		overflow: hidden;
		@include mobile {
			height: auto;
			flex-direction: column;
			padding: 0 5vw;
		}
		.title {
			position: relative;
			width: 12vw;
			height: calc(100vh - 160px);
			border-right: 1px solid $black;
			box-sizing: border-box;
			background-color: $white;
			@include mobile {
				padding: 10vh 0;
				display: flex;
				justify-content: center;
				width: 100%;
				height: auto;
				border-right: 0;
			}
			h2 {
				position: absolute;
				top: 50%;
				left: 50%;
				width: auto;
				display: flex;
				margin: 0;
				transform: translate(-50%, -50%) rotate(-90deg);
				transform-origin: center center;
				@include mobile {
					position: relative;
					top: auto;
					left: auto;
					transform: translate(0, 0) rotate(0deg);
				}
				span {
					position: relative;
					display: flex;
					font-family: 'Suisse Int';
					font-weight: 500;
					color: $black;
					font-size: 7vw;
					line-height: 12vw;
					text-transform: uppercase;
					@include mobile {
						font-size: 15vw;
						line-height: 15vw;
					}
					svg {
						height: 6vw;
						margin: 3vw 0;
						width: auto;
						fill: $black;
						@include mobile {
							height: 11vw;
							margin: 2vw 0;
						}
					}
					&:last-of-type {
						margin-left: 0.8vw;
					}
				}
			}
		}
		.list--container {
			position: relative;
			width: calc(100% - 12vw);
			@include mobile {
				width: 100%;
			}
			.list--wrapper {
				position: relative;
				display: flex;
				height: 100%;
				@include mobile {
					flex-direction: column;
				}
				.patent {
					position: relative;
					width: 25%;
					flex-shrink: 0;
					height: 100%;
					transition-property: transform;
					border-right: 1px solid $black;
					box-sizing: border-box;
					background-color: transparent;
					transition: background-color .5s ease-in-out 0s;
					@include mobile {
						border: 0;
						width: 100%;
						margin-bottom: 10vh;
					}
					&:last-of-type {
						border: 0;
					}
					a {
						position: relative;
						display: flex;
						width: 100%;
						height: 100%;
						justify-content: center;
						align-items: center;
						.image {
							position: relative;
							width: 35%;
							img {
								width: 100%;
								height: auto;
								display: block;
							}
						}
					}
					&:hover {
						background-color: $black;
						transition: background-color .5s ease-in-out 0s;
						@include mobile {
							background-color: transparent;
						}
					}
				}
			}
		}
	}
}
</style>